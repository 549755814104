import React from "react";
import Head from "next/head";

import "bootstrap/dist/css/bootstrap.css";
import "../styles/root.scss";
import "../config/icon_library";

import { wrapper } from "../redux/store";

import Modal from "react-modal";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import { GOOGLE_GA_MEASUREMENT_ID, GOOGLE_GA_TRACKING_ID } from "../utils/constants";

Modal.setAppElement("#__next");

ReactGA_UA.initialize(GOOGLE_GA_TRACKING_ID);
ReactGA_4.initialize(GOOGLE_GA_MEASUREMENT_ID);

// This default export is required in a new `pages/_app.js` file.
class MyApp extends React.Component {
    render() {
        const { Component, pageProps } = this.props;
        return (
            <>
                <Head>
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/site.webmanifest" />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#DE2C48" />
                    <meta name="msapplication-TileColor" content="#da532c" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="apple-itunes-app" content="app-id=1559929453"></meta>
                    <meta property="fb:app_id" content="899774864081824" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                    ></meta>
                    <meta
                        name="google-site-verification"
                        content="etjAiv-i_cLYdb12CDsjC9OJ2nHNL9L7M6_7x23Esw8"
                    />
                    <meta name="theme-color" content="#DE2C48"></meta>
                    <title key="title">
                        Spara Appið | Tilboð, afslættir og allskonar vildarkjör!
                    </title>
                    <meta
                        name="description"
                        content="Sameinaðu vildarkjörin þín og fáðu afslætti, tilboð, 2 fyrir 1 og önnur vildarkjör á einum stað. Stéttarfélög, starfsmannafélög og nemendafélög nota Spara Appið."
                    />
                    <link href="/apple-app-site-association" />
                    <script type="text/javascript" src="/js/redirect.js"></script>
                    <meta name="apple-itunes-app" content="app-id=1559929453"></meta>
                    <meta property="fb:app_id" content="899774864081824" />
                    <meta
                        key="og:image"
                        property="og:image"
                        content="https://www.spara.is/spara-preview.png"
                    />

                    <meta
                        key="og:title"
                        property="og:title"
                        content="Spara Appið | Tilboð, afslættir og allskonar vildarkjör!"
                    />
                    <meta key="og:type" property="og:type" content="website" />
                    <meta key="og:url" property="og:url" content="https://www.spara.is/" />
                    <meta
                        key="og:description"
                        property="og:description"
                        content="Sameinaðu vildarkjörin þín og fáðu afslætti, tilboð, 2 fyrir 1 og önnur vildarkjör á einum stað. Stéttarfélög, starfsmannafélög og nemendafélög nota Spara Appið."
                    />
                </Head>
                <Component {...pageProps} />
            </>
        );
    }
}

export default wrapper.withRedux(MyApp);
