import { createWrapper } from "next-redux-wrapper";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducers";

const makeStore = (initialState) => {
    return createStore(rootReducer, initialState, composeWithDevTools());
};
export const wrapper = createWrapper(makeStore);
