export const types = {
    submit_code: "submit_code",
};

export const submitCodeAction = (code) => {
    return {
        type: types.submit_code,
        payload: code,
    };
};
