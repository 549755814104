import { types } from "../actions";

const initialState = {
    code: "",
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.submit_code: {
            return state;
        }
        default: {
            return state;
        }
    }
};

export default authReducer;
